import React, { useState, useEffect } from "react";
import {post} from "../util/util.js"
import {Card, Container, Badge, Tab, Tabs} from "react-bootstrap"
import moment from "moment"

export default function Bet(props){
    const [betHistory, setBetHistory] = useState([])
    const [summary, setSummary] = useState([])

    const fetchDate = ()=>{
        post('/bet/getHistory',{},d=>{
            setBetHistory(d.data||[])
            let obj ={ttl:d.data.length, win:0, winAmt:0, betAmt:0, time:moment().format("HH:mm:s")}
            d.data.forEach(t=>{
                obj.winAmt += parseInt(t.winAmt)
                obj.betAmt += parseInt(t.betAmt)
                if(t.winAmt>0)
                    obj.win += 1
            })
            setSummary(obj)
        })
    }

    useEffect(()=>{
        fetchDate()
        setInterval(()=>{
            fetchDate()
        },10000)
      },[])

    return (
        <Container style={{textAlign:'center'}}>
        <Badge bg="secondary">48小時內結果(贏 / 總)：{summary.win} / {summary.ttl}</Badge>
        <Badge bg="info">(下注金額, 贏金額)：{summary.betAmt}, {summary.winAmt}</Badge>
        <Badge bg="primary">{summary.time}</Badge>

        <Tabs
            defaultActiveKey="betHistory"
            id="uncontrolled-tab-example"
        >   

            <Tab eventKey="betHistory" title="Bet">
                {betHistory.map((t, i)=>{
                        return <Card key={i} border={t.winAmt>0?'success':'light'}>
                        <Card.Header>{t.frontEndID} {t.h +':' + t.a} ({t.hr +':' + t.ar}) </Card.Header>
                        <Card.Body>
                            <Card.Title>{t.createDate} 余額:{t.afterBetTotal}</Card.Title>
                            <Card.Text>比賽時間:{t.runTime} 賠率：{t.odds} 下注金額:{t.betAmt} 贏:{t.winAmt} </Card.Text>
                        </Card.Body>
                    </Card>
                    })}
            </Tab>
            <Tab eventKey="win" title="Win">
            </Tab>

        </Tabs>
                
                
        </Container>
    )
}